import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Svg = styled.svg`
  filter: drop-shadow(0 4px 4px ${({ theme }) => theme.colors.gray});
  stroke-width: 6;
`;

const Ribbon = ({ borderColor, color }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 249.5 474.5">
    <path
      stroke={borderColor || 'white'}
      fill={color || 'white'}
      d="M245 459L125 299 5 459V-5h240v464z"
    />
  </Svg>
);

Ribbon.propTypes = {
  borderColor: PropTypes.string,
  color: PropTypes.string
};

export default Ribbon;
