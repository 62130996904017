import React from 'react';
import PropTypes from 'prop-types';

import { Box } from './system';
import LazyImage from './LazyImage';
import Placeholder from './svg/Placeholder';

import theme from '../styles/theme';

const BGImage = ({ src, alt, fit = 'contain', ratio = 1, placeholder = null, ...props }) => (
  <Box position="relative" height="0" pb={`${ratio * 100}%`} {...props}>
    {placeholder ? (
      <Placeholder color={theme.colors.lightgray} />
    ) : (
      <LazyImage
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        fit={fit}
        width="100%"
        height="100%"
        src={src}
        alt={alt}
      />
    )}
  </Box>
);

BGImage.propTypes = {
  alt: PropTypes.string.isRequired,
  fit: PropTypes.string,
  placeholder: PropTypes.bool,
  ratio: PropTypes.number,
  src: PropTypes.string
};

export default BGImage;
