const getSizedImageUrl = (size, url) => {
  if (!url) return '';

  let urlPieces = [];

  if (url.includes('.jpg')) {
    urlPieces = url.split('.jpg');
    return `${urlPieces[0]}_${size}.jpg${urlPieces[1]}`;
  } else if (url.includes('.JPG')) {
    urlPieces = url.split('.JPG');
    return `${urlPieces[0]}_${size}.JPG${urlPieces[1]}`;
  } else if (url.includes('.jpeg')) {
    urlPieces = url.split('.jpeg');
    return `${urlPieces[0]}_${size}.jpeg${urlPieces[1]}`;
  } else if (url.includes('.JPEG')) {
    urlPieces = url.split('.JPEG');
    return `${urlPieces[0]}_${size}.JPEG${urlPieces[1]}`;
  } else if (url.includes('.png')) {
    urlPieces = url.split('.png');
    return `${urlPieces[0]}_${size}.png${urlPieces[1]}`;
  } else if (url.includes('.PNG')) {
    urlPieces = url.split('.PNG');
    return `${urlPieces[0]}_${size}.PNG${urlPieces[1]}`;
  } else if (url.includes('.gif')) {
    urlPieces = url.split('.gif');
    return `${urlPieces[0]}_${size}.gif${urlPieces[1]}`;
  } else if (url.includes('.GIF')) {
    urlPieces = url.split('.GIF');
    return `${urlPieces[0]}_${size}.GIF${urlPieces[1]}`;
  }
  return url;
};

export { getSizedImageUrl };
