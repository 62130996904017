import React from 'react';
import PropTypes from 'prop-types';

import { Box, Text } from './system';

const Label = ({
  bg = 'yellow',
  children,
  color = 'black',
  fullWidth = false,
  outline = false,
  shadow,
  ...props
}) => (
  <Box
    display={fullWidth ? 'block' : 'inline-block'}
    borderColor={outline ? bg : 'transparent'}
    borderStyle={outline ? 'solid' : 'none'}
    borderWidth={outline ? '1px' : 0}
    textAlign="center"
    mb={1}
    {...props}
  >
    <Text
      bg={outline ? 'white' : bg}
      color={color}
      px={2}
      py="0.15rem"
      fontSize={0}
      fontWeight="bold"
      boxShadow={shadow ? 'sm' : ''}
      display={fullWidth ? 'block' : 'inline-block'}
      uppercase
    >
      {children}
    </Text>
  </Box>
);

Label.propTypes = {
  bg: PropTypes.string,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  outline: PropTypes.bool,
  shadow: PropTypes.bool
};

export default Label;
