import React from 'react';
import PropTypes from 'prop-types';

import Ribbon from './svg/Ribbon';
import { Box } from './system';

const Flag = ({ color = 'white', borderColor = 'white', ...props }) => (
  <Box {...props} position="relative">
    <Ribbon color={color} borderColor={borderColor} />
    <Box position="absolute" top={0} left={0} bottom={0} right={0}>
      {props.children}
    </Box>
  </Box>
);

Flag.propTypes = {
  borderColor: PropTypes.string,
  color: PropTypes.string
};

export default Flag;
