import React from 'react';
// lazysizes imported into App.js

import { Image } from './system';

import theme from '../styles/theme';

const LazyImage = ({ className = '', css = '', src, ...rest }) => (
  <Image
    className={`${className} lazyload`}
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
    data-src={src}
    css={`
      ${css}

      &.lazyload,
      &.lazyloading {
        opacity: 0;
        transition: opacity ${theme.transitions.lg} ease;
      }

      &.lazyloaded {
        opacity: 1;
        transition: opacity ${theme.transitions.lg} ease;
      }
    `}
    {...rest}
  />
);

LazyImage.defaultProps = {
  color: 'transparent'
};

export default LazyImage;
