import React from 'react';

import Container from '../components/Container';
import FeedItem from '../components/FeedItem';
import { Box, Text } from '../components/system';

const Feed = ({ category, items, gifts }) =>
  items.length > 0 && (
    <Box mb={5} px={3}>
      <Container>
        <Box mx={-3} bg="nearwhite" borderRadius={category ? '8px 8px 8px 8px' : 'none'}>
          {category && (
            <Box
              width={1}
              mb={2}
              px={3}
              py={[3]}
              bg="nearblack"
              boxShadow="sm"
              textAlign="center"
              borderRadius="8px 8px 0 0"
            >
              <Text as="h3" fontWeight="bold" color="white" uppercase fontSize={[2, 3]}>
                {category.title}
              </Text>
            </Box>
          )}
          <Box width={1} px={[0, 3]} pb={[0, 3]} display="flex" flexWrap="wrap">
            {items ? (
              items.map(({ freeGiftHandle, handle, image, price, title, vendor }, i) => (
                <FeedItem
                  key={`${i}-${title}`}
                  gift={gifts.filter(gift => gift.handle === freeGiftHandle)[0]}
                  title={title}
                  image={{ src: image, alt: title }}
                  price={price}
                  url={`/deals/${handle}`}
                  vendor={vendor}
                />
              ))
            ) : (
              <FeedItem
                gift={null}
                title={`No Current Deals For ${category.title}`}
                image={{ src: null, alt: `More ${category.title} Deals Soon` }}
                price={null}
                url={null}
                vendor="Supplement Deals"
              />
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );

export default Feed;
