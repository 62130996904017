import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';

import BGImage from './BGImage';
import Flag from './Flag';
import Label from './Label';
import { Box, Button, Card, Text } from './system';
import { getSizedImageUrl } from '../helpers/responsiveImages';

import theme from '../styles/theme';

const ConditionalLink = ({ url, ...props }) =>
  url ? <Link to={url} {...props} /> : <Fragment {...props} />;

const FeedItem = ({ gift, title, image, price, url, vendor }) => (
  <Box width={[1, 1 / 2, null, 1 / 3]} p={3}>
    <ConditionalLink url={url} style={{ textDecoration: 'none' }}>
      <Card
        bg="white"
        position="relative"
        textAlign="center"
        css={`
          transition: all ${theme.transitions.md} ease;
          &:hover {
            box-shadow: ${theme.shadows.lg};
            opacity: 0.85;
            transform: translateY(-5px);

            button {
              background: ${theme.colors.subduedyellow};
              border-color: ${theme.colors.subduedyellow};
              color: ${theme.colors.nearblack};
            }
          }
        `}
      >
        {url && (
          <Box position="absolute" top={0} left="1rem" width="3rem" zIndex={9}>
            <Flag borderColor={theme.colors.nearblack} color={theme.colors.nearblack}>
              <p style={{ marginTop: '1.25rem', lineHeight: '1em' }}>
                <Text
                  color="white"
                  fontSize={0}
                  fontWeight="bold"
                  css={{ textTransform: 'uppercase' }}
                >
                  Deal!
                </Text>
              </p>
            </Flag>
          </Box>
        )}
        {gift && (
          <Box position="absolute" top="0.3rem" right="0.3rem" zIndex={9}>
            <Label bg="subduedyellow" color="black" shadow>
              Free Gift
            </Label>
          </Box>
        )}
        <Box px={4} py={3} width="65%" mx="auto">
          <BGImage
            src={image.src && getSizedImageUrl('320x', image.src)}
            alt={image.alt}
            ratio={1}
            fit="contain"
            placeholder={!image.src}
          />
        </Box>
        <Box bg="nearwhite" p={4}>
          <Text as="p" color="darkgray" mb={1} fontSize={0} uppercase>
            {vendor}
          </Text>
          <Text as="p" fontWeight="bold" color="nearblack" mb={2} fontSize={[2, 3]}>
            {title}
          </Text>
          <p>
            <Text color="nearblack">{price}</Text>
          </p>
          {url ? (
            <Button
              bg="yellow"
              borderColor="yellow"
              fontWeight="bold"
              color="nearblack"
              px={4}
              width={1}
              maxWidth="12rem"
              type="button"
              aria-label="Navigate to Deal Page"
              mt={3}
              mb={2}
              fontSize={2}
            >
              View Deal
            </Button>
          ) : (
            <Box>
              <Text as="p" fontSize={1}>
                Deals are regularly updated so make sure you check back soon!
              </Text>
            </Box>
          )}
        </Box>
      </Card>
    </ConditionalLink>
  </Box>
);

FeedItem.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  price: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  vendor: PropTypes.string.isRequired
};

export default FeedItem;
