import React from 'react';

import { Svg } from '../system/Svg';

const Placeholder = ({ color, ...props }) => (
  <Svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="18.8 27.5 581.3 544.9">
    <path
      fill={color}
      d="M564.8 238.5H458.2v-70.3a35.2 35.2 0 0 0-60-24.9L257.6 284a35.1 35.1 0 0 0 0 49.7l80.6 80.7-98.2 98.1a35.1 35.1 0 1 0 49.7 49.8l123-123a35.1 35.1 0 0 0 0-49.8l-80.6-80.6L388 253v20.6a35.2 35.2 0 0 0 35.1 35.2h141.8a35.2 35.2 0 0 0 0-70.3z"
    />
    <path
      fill={color}
      d="M328.3 59.8a35.2 35.2 0 0 0-44.4 4.4L187.3 161a35.1 35.1 0 1 0 49.7 49.7l76.3-76.3 25.7 18.5 34.3-34.3a69.9 69.9 0 0 1 20-13.7l-65-45z"
    />
    <path
      fill={color}
      d="M232.7 358.5c-8.9-8.9-14.8-19.9-18-31.7L29.1 512.4a35.1 35.1 0 1 0 49.7 49.8l178.8-178.8-24.9-24.9z"
    />
    <circle fill={color} cx="475.8" cy="80.3" r="52.7" />
  </Svg>
);

export default Placeholder;
